import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  connectWalletAsync, getPastMintsSelectedAddress,
  newWalletConnected, NftMint, SimpleAsyncState, walletDisconnected
} from '@cyberpnk/component-library';
import { getNftFaucetContract, getTotalSupply, mint } from './nftFaucetAPI';

export interface NftFaucetState {
  totalSupply: SimpleAsyncState<number>;
  pastMints: SimpleAsyncState<NftMint[]>;
  mint: SimpleAsyncState<void>;
}

const initialState: NftFaucetState = {
  pastMints: {
    loading: false
  },
  mint: {
    loading: false
  },
  totalSupply: {
    loading: false
  }
};

export const getTotalSupplyAsync = createAsyncThunk(
  'nftFaucet/getTotalSupply',
  async (args, thunkAPI) => {
    const total = await getTotalSupply();
    return total;
  }
);

export const mintAsync = createAsyncThunk(
  'nftFaucet/mint',
  async ({ howMany }: { howMany: number }, thunkAPI) => {
    await mint(howMany);
    return "true";
  }
);

export const getPastMintsSelectedAddressAsync = createAsyncThunk(
  'nftFaucet/getPastMintsSelectedAddress',
  async (args, thunkAPI) => {
    return getPastMintsSelectedAddress(getNftFaucetContract);
  }
);

export const nftFaucetSlice = createSlice({
  name: 'nftFaucet',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(mintAsync.pending, (state) => {
        state.mint = { loading: true };
      })
      .addCase(mintAsync.fulfilled, (state, action) => {
        state.mint = { loading: false };
      })
      .addCase(mintAsync.rejected, (state, action) => {
        state.mint = {
          loading: false,
          error: "There was an error with the transaction"
        };
      })
      .addCase(getTotalSupplyAsync.pending, (state) => {
        state.totalSupply = { loading: true };
      })
      .addCase(getTotalSupplyAsync.fulfilled, (state, action) => {
        state.totalSupply = { loading: false, response: action.payload };
      })
      .addCase(getTotalSupplyAsync.rejected, (state, action) => {
        state.totalSupply = { loading: false, error: "" };
      })

      .addCase(getPastMintsSelectedAddressAsync.pending, (state) => {
        state.pastMints = {
          loading: true
        };
      })
      .addCase(getPastMintsSelectedAddressAsync.fulfilled, (state, action) => {
        state.pastMints = {
          loading: false,
          response: action.payload
        };
      })
      .addCase(getPastMintsSelectedAddressAsync.rejected, (state, action) => {
        state.pastMints = {
          loading: false,
          error: "There was an error with the transaction"
        };
      })

      .addCase(connectWalletAsync.pending, (state) => {
        state.totalSupply = { loading: false };
        state.mint = { loading: false };
        state.pastMints = { loading: false };
      })
      .addCase(newWalletConnected, (state) => {
        state.totalSupply = { loading: false };
        state.mint = { loading: false };
        state.pastMints = { loading: false };
      })
      .addCase(walletDisconnected, (state) => {
        state.totalSupply = { loading: false };
        state.mint = { loading: false };
        state.pastMints = { loading: false };
      })
  },
});

export const selectLoading = (state: RootState) => state.nftFaucet?.mint.loading || state.nftFaucet?.pastMints.loading;
export const selectError = (state: RootState) => state.nftFaucet?.mint.error || state.nftFaucet?.pastMints.error;
export const selectTotalSupply = (state: RootState) => state.nftFaucet?.totalSupply.response;


export default nftFaucetSlice.reducer;
