import Web3 from "web3";
import { AbiItem } from "web3-utils";
import artefact from "../../NumbersOnTap.json";
import { callAndWaitForTransfer, ContractPerNetwork, GetContract, getNetworkFromWallet } from "@cyberpnk/component-library";
import addresses from "../../contract-addresses.json";

declare global {
  interface Window { ethereum: any; }
}

const nftFaucetContract: ContractPerNetwork = {};
export const getNftFaucetContract: GetContract = async () => {
  const web3 = new Web3(window.ethereum);
  const network = await getNetworkFromWallet(web3);
  const nftFaucetAddress = addresses[network].NumbersOnTap;
  if (!nftFaucetContract[network]) {
    nftFaucetContract[network] = await new web3.eth.Contract(artefact.abi as AbiItem[], nftFaucetAddress);
  }
  return nftFaucetContract[network];
}

export const getTotalSupply = async () => {
  const nftFaucetContract = await getNftFaucetContract();
  return nftFaucetContract.methods.totalSupply().call();
}

export const mint = async (howMany: number) => {
  return callAndWaitForTransfer(getNftFaucetContract, "claim", [howMany]);
}

