import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { connectWalletReducer, hasWalletAsync, listenForNetworkChange, listenForWalletChange, nftDisplayReducer } from '@cyberpnk/component-library';
import nftFaucetReducer from '../features/NftFaucet/nftFaucetSlice';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { reconnectEpic, mintEpic } from '../features/NftFaucet/nftFaucetEpic';

const epicMiddleware = createEpicMiddleware();

export const store = configureStore({
  reducer: {
    connectWallet: connectWalletReducer,
    nftFaucet: nftFaucetReducer,
    nftDisplay: nftDisplayReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(epicMiddleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

store.dispatch(hasWalletAsync());
store.dispatch(listenForWalletChange());
store.dispatch(listenForNetworkChange());

export const rootEpic = combineEpics(
  reconnectEpic,
  mintEpic
);

epicMiddleware.run(rootEpic);
