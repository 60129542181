import React from 'react';

import { useAppSelector } from '../../app/hooks';
import styles from './TapLogo.module.css';
import img578 from './578.svg';
import img3927 from './3927.svg';
import img6144 from './6144.svg';
import img8361 from './8361.svg';
import img28361 from './28361.svg';
import img56144 from './56144.svg';
import img83927 from './83927.svg';
import imgFaucet from './faucet-solid.svg';
import { selectNetwork, Network } from '@cyberpnk/component-library';

export function TapLogo() {
  const network = useAppSelector(selectNetwork);
  const isRinkeby = network === Network.Rinkeby;
  const isUnknownNetwork = network !== Network.Rinkeby && network !== Network.Mainnet;

  const numberImages = !network || isUnknownNetwork ? [] : [
    isRinkeby ? img83927 : img8361,
    isRinkeby ? img56144 : img6144,
    isRinkeby ? img28361 : img3927,
    img578
  ];

  return (
    <div className={styles.logo}>
      <img alt="" width="200" height="200" src={imgFaucet} className={styles.faucet} />
      {
        numberImages.map((numberImage, index) => <img alt="" src={numberImage} className={styles.number} key={index} />)
      }
    </div>
  );
}
