import { connectWalletAsync, loadPastMintsAsync } from "@cyberpnk/component-library";
import { ofType } from "redux-observable";
import { switchMap } from "rxjs/operators";
import { getNftFaucetContract } from "./nftFaucetAPI";
import { getTotalSupplyAsync, mintAsync } from "./nftFaucetSlice";

export const reconnectEpic = (action$: any) => action$.pipe(
  ofType(connectWalletAsync.fulfilled),
  switchMap(() => [
    getTotalSupplyAsync(),
    loadPastMintsAsync({getContract: getNftFaucetContract}),
  ]),
);

export const mintEpic = (action$: any) => action$.pipe(
  ofType(mintAsync.fulfilled),
  switchMap(() => [
    getTotalSupplyAsync(),
    loadPastMintsAsync({getContract: getNftFaucetContract}),
  ]),
);
