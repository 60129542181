import React from 'react';
import { ConnectWallet, ConnectWalletStatus, Footer, NftDisplay, selectConnected, selectNetwork, TopBar } from '@cyberpnk/component-library';
import styles from './App.module.css';
import { NftFaucet } from './features/NftFaucet/NftFaucet';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { Network } from '@cyberpnk/component-library';
import { TapLogo } from "./features/TapLogo/TapLogo";
import { getNftFaucetContract } from './features/NftFaucet/nftFaucetAPI';
import addresses from "./contract-addresses.json";
import { version } from '../package.json';

function App() {
  const connected = useAppSelector(selectConnected);
  const network = useAppSelector(selectNetwork);

  const isKnownNetwork = network === Network.Rinkeby || network === Network.Mainnet;

  const openseaCollection = network === Network.Mainnet ? "numbers-on-tap-1" : "numbersontap";

  return (
    <div className={styles.App}>
      <TopBar>
        <ConnectWalletStatus getContract={getNftFaucetContract} useAppDispatch={useAppDispatch} useAppSelector={useAppSelector} />
      </TopBar>
      <div className={styles.taplogo}><TapLogo /></div>
      <main>
        <h1>Numbers on Tap</h1>
        <h2>Claim free random numbers as Ethereum NFTs to use however you want</h2>
        <ConnectWallet getContract={getNftFaucetContract} useAppDispatch={useAppDispatch} useAppSelector={useAppSelector} />
        {connected && isKnownNetwork ? <>
          <NftFaucet></NftFaucet>
          <NftDisplay useAppSelector={useAppSelector} textEmpty={" "} textTop={"Your recent mints"} />
        </>
          : null
        }
      </main>
      <div className={styles.footer}>
        <Footer network={network} contractAddress={addresses[network as Network]?.NumbersOnTap} version={version} openSeaCollection={openseaCollection} />
      </div>
    </div>
  );
}

export default App;
