import React, { useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { mintAsync, selectError, selectLoading, selectTotalSupply } from './nftFaucetSlice';
import styles from './NftFaucet.module.css';
import { MySpinner } from '../../ui-components/MySpinner/MySpinner';
import { ErrorMessage, Button, NumberInput, Network, selectNetwork } from '@cyberpnk/component-library';

export function NftFaucet() {
  const loading = useAppSelector(selectLoading);
  const error = useAppSelector(selectError);
  const dispatch = useAppDispatch();
  const totalSupply = useAppSelector(selectTotalSupply);
  const network = useAppSelector(selectNetwork);
  const [inputValue, setInputValue] = useState<string | undefined>("1");
  const noValue = inputValue === undefined || isNaN(+inputValue);
  const min = 1;
  const max = 10;
  const lessThanMin = !noValue && inputValue !== undefined && +inputValue < min;
  const moreThanMax = !noValue && inputValue !== undefined && +inputValue > max;
  const invalidInput = noValue || lessThanMin || moreThanMax;
  const maxSupply = network === Network.Rinkeby ? 1000000 : 10000;

  return (
    <div>
      <div className={styles.claim}>

        <p>{totalSupply !== undefined ? totalSupply : ''} / {maxSupply}</p>

        <NumberInput cta={true} onChange={(value) => setInputValue(value)} disabled={loading} error={invalidInput} min={min} max={max} value={inputValue} />{' '}
        <Button cta={true} disabled={loading} onClick={() => {
          dispatch(mintAsync({ howMany: inputValue ? +inputValue : 0 }));
        }}>Claim Numbers</Button>
        {noValue ? <ErrorMessage>Enter a value</ErrorMessage> : null}
        {lessThanMin ? <ErrorMessage>At least {min}</ErrorMessage> : null}
        {moreThanMax ? <ErrorMessage>No more than {max}</ErrorMessage> : null}
        {!invalidInput ? <p>&nbsp;</p> : null}
        {loading ? <p>Pending transaction...<MySpinner /></p> :
          error ? <ErrorMessage>{error}</ErrorMessage> :
            <p>&nbsp;</p>}
      </div>
    </div>
  );
}
